<template>
  <div class="card">
    <div class="card-body">
      <div class="detail-container">
        <div class="title">
          <div class="left">
            <div class="company">
              <span>CMA长安马自达</span>
            </div>
            <div class="address">
              <span>江苏南京江宁诚信大道xxx号</span>
            </div>
          </div>
          <div class="right">
            <el-image style="width: 180px; height: 40px" src="static/img/ywm.png" fit="fill"></el-image>
          </div>
        </div>
        <div class="s-title"><span>客户委托单</span></div>
        <div class="s-consign">
          <div class="consign">
            <span class="name">收货方:</span>
            <span class="company">长安民生住久物流有限公司</span>
            <span class="addr">江苏省南京市江宁开发区清水亭西路222号</span>
            <span class="contact">张三</span>
            <span class="mobile">025-86541235</span>
          </div>
          <div class="consign" style="text-align:right">
            <span class="name">收货方:</span>
            <span class="company">长安民生住久物流有限公司</span>
            <span class="addr">江苏省南京市江宁开发区清水亭西路222号</span>
            <span class="contact">张三</span>
            <span class="mobile">025-86541235</span>
          </div>
        </div>
        <div class="item-detail">
          <el-table :data="tableData">
                <el-table-column  property="id" width="100px"></el-table-column>
                <el-table-column label="货物代码" property="code"></el-table-column>
                <el-table-column label="货物描述" property="desc"></el-table-column>
                <el-table-column label="箱数" property="box"></el-table-column>
                <el-table-column label="数量" property="quantity"></el-table-column>
                <el-table-column label="体积/重量" property="weight"></el-table-column>
            </el-table>
        </div>
        <div class="count">
          <div class="item">
            <div style="width:500px"><span>小计</span></div>
            <div style="width:100px;text-align:right"><span>20/500</span></div>
          </div>
          <div class="item">
            <div style="width:500px"><span>计费单位</span></div>
            <div style="width:100px;text-align:right"><span>0000</span></div>
          </div>
          <div class="item">
            <div style="width:500px"><span>运费</span></div>
            <div style="width:100px;text-align:right"><span>500</span></div>
          </div>
          <div class="item">
            <div style="width:650px;border-bottom: 2px solid black !important;"></div>
          </div>
          <div class="item">
            <div style="width:500px;color:#627ca0;font-size:25px;"><span style="font-weight:600;">合计</span></div>
            <div style="width:100px;text-align:right;color:black;font-size:25px"><span style="font-weight:600;">0000</span></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      order: undefined,
      tableData: [{
          id: 1,
          code: 'PET-XXX-YYY-098',
          desc: '仪表盘',
          box: '10',
          quantity: '150',
          weight: '0.2/4.5'
        },{
          id: 2,
          code: 'PET-EEE-0987',
          desc: '导航总成',
          box: '20',
          quantity: '150',
          weight: '1.5/2.8'
        },{
          id: 3,
          code: 'POO-UUU-RREE-0928',
          desc: 'HIFI BOSE',
          box: '50',
          quantity: '250',
          weight: '4/8'
        },{
          id: 4,
          code: 'OOO-YTR-0923',
          desc: '前车灯',
          box: '480',
          quantity: '10',
          weight: '15/158'
        }
        ]
    };
  },
  components: {},
  created() {
    this.order = JSON.parse(localStorage.getItem("order"));
    console.log(JSON.stringify(this.order));
    this.getOrderDetail();
  },
  methods: {
    getOrderDetail() {}
  }
};
</script>
<style lang="scss" scoped>
/deep/ .card-body {
    padding: 5rem !important;
}
.detail-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  // background-color: white;
}
.title {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .left {
    // background-color: red;
    display: flex;
    flex-direction: column;
    .company {
      // font-weight: 400px;
      font-size: 20px;
      color: #627ca0;
    }
    .address {
      font-size: 10px;
      color: #a7aeb3;
    }
  }
  .right {
    // background-color: blue;
  }
}
.s-title {
  font-size: 38px;
  color: #627ca0;
  padding-top: 80px;
}
.s-consign {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .consign {
    display: flex;
    flex-direction: column;
    span{
      margin-top: 5px;
    }
    .name{
      font-size: 30px;
      font-weight: 800;
    }
  }

}
.item-detail {
  margin-top:60px
}
.count {
  // width:50%;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  .item{
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    padding-top:20px
  }
}
/deep/ .el-table thead {
    color: black !important;
    font-weight: 500;
    border-bottom-color: black !important;
}
/deep/ .el-table th.is-leaf {
    border-bottom: 2px solid black !important;
}
</style>