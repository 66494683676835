var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validate($event)}}},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-sm-12"},[_c('h5',{staticClass:"info-text"},[_vm._v(" Are you living in a nice area? ")])]),_c('div',{staticClass:"col-sm-7"},[_c('ValidationProvider',{attrs:{"name":"streetName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"label":"Street Name","error":failed ? 'The Street Name field is required': null,"hasSuccess":passed,"name":"street name"},model:{value:(_vm.streetName),callback:function ($$v) {_vm.streetName=$$v},expression:"streetName"}})]}}])})],1),_c('div',{staticClass:"col-sm-3"},[_c('ValidationProvider',{attrs:{"name":"streetNumber","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"label":"Street No","error":failed ? 'The Street Number field is required': null,"hasSuccess":passed,"name":"street number"},model:{value:(_vm.streetNo),callback:function ($$v) {_vm.streetNo=$$v},expression:"streetNo"}})]}}])})],1),_c('div',{staticClass:"col-sm-5"},[_c('ValidationProvider',{attrs:{"name":"city","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"label":"City","error":failed ? 'The City field is required': null,"hasSuccess":passed,"name":"city"},model:{value:(_vm.city),callback:function ($$v) {_vm.city=$$v},expression:"city"}})]}}])})],1),_c('div',{staticClass:"col-sm-5"},[_c('label',[_vm._v("Country")]),_c('ValidationProvider',{attrs:{"name":"country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
return [_c('fg-input',{attrs:{"error":failed ? 'The Country field is required': null}},[_c('el-select',{staticClass:"select-primary",attrs:{"name":"country"},model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}},_vm._l((_vm.countryOptions),function(country){return _c('el-option',{key:country,staticClass:"select-primary",attrs:{"label":country,"value":country}})}),1)],1)]}}])})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }