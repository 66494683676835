<template>
  <div class="row">
    <div class="col-md-12 card">
      <div class="row">
        <div class="col-sm-12 opertion">
          <!-- <p-button type="info" @click="dispatch" v-hasPermi="['/oms/order/list:plan']">
            <i slot="label" class="nc-icon nc-send"></i>
            
          </p-button> -->

          <!-- <p-button type="success" @click="orderUpdate()" v-hasPermi="['/oms/order/list:update']">
            <i slot="label" class="el-icon-edit"></i>
            修改
          </p-button>

          <p-button type="warning" @click="deleteOrder(0)" v-hasPermi="['/oms/order/list:recall']">
            <i slot="label" class="el-icon-refresh-right"></i>
            撤回
          </p-button>

          <p-button type="danger" @click="deleteOrder(1)" v-hasPermi="['/oms/order/list:delete']">
            <i slot="label" class="el-icon-delete"></i>
            删除
          </p-button>

          <p-button type="primary" @click="clock()" v-hasPermi="['/oms/order/list:clock']">
            <i slot="label" class="el-icon-location-outline"></i>
            打卡
          </p-button>
          <p-button type="primary" @click="planEdit()">
            <i slot="label" class="el-icon-edit"></i>
            计划制作
          </p-button> -->
        </div>
      </div>
      <div class="card-body row">
        <div class="col-sm-6">
          <!-- <div class="row form-horizontal">
            <label class="col-form-label">订单号</label>
            <div class="col-md-4">
              <fg-input placeholder="订单号" v-model="searchForm.no"></fg-input>
            </div>
            <div class="col-md-4">
              <p-button type="info" style="margin-top: 0;" @click="searchData">
                <i slot="label" class="nc-icon nc-zoom-split"></i>
                搜索
              </p-button>
            </div>
          </div> -->
        </div>

        <div class="col-sm-6 pageOption">
          <el-select class="select-default" v-model="pagination.perPage" placeholder="每页显示">
            <el-option
              class="select-default"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </div>
        <div class="col-sm-12 mt-2">
          <el-table
            empty-text="暂无数据"
            class="table-striped"
            :data="tableData"
            border
            style="width: 100%"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="40"></el-table-column>
            <!-- <el-table-column type="expand"></el-table-column> -->
            <el-table-column min-width="80" prop="no" label="订单号" width="200">
              <template slot-scope="scope">
                <a
                  href="javascript:void(0)"
                  style="text-decoration: underline;cursor: pointer;"
                  @click="getTrackInfo(scope.row)"
                >{{scope.row.no}}</a>
              </template>
            </el-table-column>
            <el-table-column prop="consignee" label="收货方"></el-table-column>
            <el-table-column prop="consigneeCode" label="收货方代码" width="180"></el-table-column>
            <el-table-column prop="consigneeAddr" label="收货方地址"></el-table-column>
            <el-table-column prop="consigneeContact" label="收货方联系人"></el-table-column>
            <el-table-column prop="status" label="状态" width="80">
              <template slot-scope="scope">{{orderStatus[scope.row.status]}}</template>
            </el-table-column>
            <el-table-column min-width="100" prop="orderDate" label="订单时间" width="170">
              <template slot-scope="scope">{{formatterDate1(scope.row.orderDate)}}</template>
            </el-table-column>
            <!-- <el-table-column min-width="100" prop="status" label="计划发运" width="120">
              <template slot-scope="scope">{{formatterDate(scope.row.planDate)}}</template>
            </el-table-column> -->
            <!-- <el-table-column width="100" prop="truck" label="物流跟踪">
              <template slot-scope="scope">
                <a
                  href="javascript:void(0)"
                  @click="getTrackQrcode(scope.row.id)"
                ><img src='static/img/erweima.jpg' width="30" height="30" /></a>
              </template>
            </el-table-column>
            <el-table-column width="100" prop="truck" label="操作">
              <template slot-scope="scope">
                <a
                  href="javascript:void(0)"
                  @click="getOrderDetail(scope.row)"
                >委托单</a>
              </template>
            </el-table-column> -->
          </el-table>
        </div>
        <div class="col-sm-6 pagination-info">
          <p class="category pageFooter">从{{to===0 ? 0 : from + 1}} 到 {{to}} 条记录--总记录数为 {{total}} 条</p>
        </div>
        <div class="col-sm-6">
          <p-pagination
            class="pull-right pageFooter"
            @page-change="pageChange"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="pagination.total"
          ></p-pagination>
        </div>
      </div>
    </div>
    <el-dialog title="发运派单" :visible.sync="dialogFormVisible" :modal-append-to-body="false">
      <form class="form-horizontal">
        <div class="row">
          <label class="col-md-3 col-form-label">司机</label>
          <div class="col-md-9">
            <!-- <fg-input placeholder="司机" v-model="dispatchForm.driver"></fg-input> -->
            <el-autocomplete
            style="width:100%;margin-bottom:10px;color:#9A9A9A"
            v-model="dispatchForm.driver"
            :fetch-suggestions="querySearchAsync0"
            placeholder="请输入司机"
            @select="handleSelect0"
          ></el-autocomplete>
          </div>

          <label class="col-md-3 col-form-label">车牌</label>
          <div class="col-md-9">
            <!-- <fg-input placeholder="车牌" v-model="dispatchForm.plateNo"></fg-input> -->
             <el-autocomplete
            style="width:100%;margin-bottom:10px;color:#9A9A9A"
            v-model="dispatchForm.plateNo"
            :fetch-suggestions="querySearchAsync1"
            placeholder="请输入车牌"
            @select="handleSelect1"
          ></el-autocomplete>
          </div>

          <label class="col-md-3 col-form-label">手机</label>
          <div class="col-md-9">
            <!-- <fg-input placeholder="手机" v-model="dispatchForm.mobile"></fg-input> -->
            <el-autocomplete
            style="width:100%;margin-bottom:10px;color:#9A9A9A"
            v-model="dispatchForm.mobile"
            :fetch-suggestions="querySearchAsync2"
            placeholder="请输入手机"
            @select="handleSelect2"
          ></el-autocomplete>
          </div>

          <label class="col-md-3 col-form-label">车型</label>
          <div class="col-md-9">
            <!-- <fg-input placeholder="车型" v-model="dispatchForm.truckType"></fg-input> -->
            <el-autocomplete
            style="width:100%;margin-bottom:10px;color:#9A9A9A"
            v-model="dispatchForm.truckType"
            :fetch-suggestions="querySearchTruckAsync"
            placeholder="车型"
            @select="handleSelectTruck"
          ></el-autocomplete>
          </div>

          <label class="col-md-3 col-form-label">承运商</label>
          <div class="col-md-9">
            <!-- <fg-input placeholder="承运商" v-model="dispatchForm.carrier"></fg-input> -->
            <el-autocomplete
            style="width:100%;margin-bottom:10px;color:#9A9A9A"
            v-model="dispatchForm.carrier"
            :fetch-suggestions="querySearchCarrierAsync"
            placeholder="承运商"
            @select="handleSelectCarrier"
          ></el-autocomplete>
          </div>

          <label class="col-md-3 col-form-label"></label>
          <div class="col-md-9">
            <fg-input placeholder="派单">
              <p-checkbox :checked="isAllocate" @on-checkbox-change="onCheckboxChange">同时派单</p-checkbox>
            </fg-input>
            <!-- <fg-input placeholder="保存派单信息">
              <p-checkbox :checked="isSaveDriverInfo" @on-checkbox-change="onCheckboxChange1">保存派单信息</p-checkbox>
            </fg-input> -->
          </div>
        </div>
      </form>
      <div slot="footer" class="dialog-footer">
        <p-button @click="dialogFormVisible = false">取 消</p-button>
        <p-button type="primary" @click="handleOrderPlan">确 定</p-button>
      </div>
    </el-dialog>
    <el-dialog title="订单更新" :visible.sync="dialogEditFormVisible" :modal-append-to-body="false">
      <form class="form-horizontal">
        <div class="row">
          <label class="col-md-3 col-form-label">订单号</label>
          <div class="col-md-9">
            <fg-input placeholder="订单号" v-model="editForm.no"></fg-input>
          </div>

          <label class="col-md-3 col-form-label">收货方</label>
          <div class="col-md-9">
            <fg-input placeholder="收货方" v-model="editForm.consignee"></fg-input>
          </div>

          <label class="col-md-3 col-form-label">收货方代码</label>
          <div class="col-md-9">
            <fg-input placeholder="收货方代码" v-model="editForm.consigneeCode"></fg-input>
          </div>

          <label class="col-md-3 col-form-label">收货方地址</label>
          <div class="col-md-9">
            <fg-input placeholder="收货方地址" v-model="editForm.consigneeAddr"></fg-input>
          </div>

          <label class="col-md-3 col-form-label">收货联系方式</label>
          <div class="col-md-9">
            <fg-input placeholder="发货联系方式" v-model="editForm.consigneeContact"></fg-input>
          </div>

          <label class="col-md-3 col-form-label">发货方</label>
          <div class="col-md-9">
            <fg-input placeholder="发货方" v-model="editForm.consignor"></fg-input>
          </div>

          <label class="col-md-3 col-form-label">发货方代码</label>
          <div class="col-md-9">
            <fg-input placeholder="发货方代码" v-model="editForm.consignorCode"></fg-input>
          </div>

          <label class="col-md-3 col-form-label">发货方地址</label>
          <div class="col-md-9">
            <fg-input placeholder="发货方地址" v-model="editForm.consignorAddr"></fg-input>
          </div>

          <label class="col-md-3 col-form-label">发货联系方式</label>
          <div class="col-md-9">
            <fg-input placeholder="发货联系方式" v-model="editForm.consignorContact"></fg-input>
          </div>
        </div>
      </form>
      <div slot="footer" class="dialog-footer">
        <p-button @click="dialogEditFormVisible = false">取 消</p-button>
        <p-button type="primary" @click="handleOrderEdit">确 定</p-button>
      </div>
    </el-dialog>
    <el-drawer :visible.sync="drawer" :direction="direction" v-scrollBar>
      <div class="header text-center">
        <h3 class="title">{{timelineName}}时间轴</h3>
      </div>

      <el-tabs v-model="activeName" type="card">
        <el-tab-pane v-for="(value,key,index) in activities" :key="index" :label="key">
          <time-line>
            <time-line-item
              v-for="(tdata, index) in activities[key]"
              :key="index"
              class="timeline-inverted"
              :badgeType="timeLineType(index)"
              :badgeIcon="timeLineIcon(index)"
            >
              <!-- <badge slot="header" type="danger">Some title</badge> -->
              <p slot="content">{{tdata.info}}</p>

              <h6 slot="footer">
                <i class="fa fa-clock-o"></i>
                {{tdata.time}}
              </h6>
            </time-line-item>
          </time-line>
          <!-- <el-timeline>
            <el-timeline-item
              v-for="(tdata, index) in activities[key]"
              :key="index"
              :timestamp="tdata.time"
            >{{tdata.info}}</el-timeline-item>
          </el-timeline>-->
        </el-tab-pane>
      </el-tabs>
    </el-drawer>
    <el-dialog title="打卡" v-if="dialogClockFormVisible" :visible.sync="dialogClockFormVisible" :modal-append-to-body="false" :destroy-on-close="true">
      <form class="form-horizontal">
        <div class="row">
          <label class="col-md-2 col-form-label">地级市</label>
          <div class="col-md-6">
            <area-cascader v-model="selected" :level="1" type="text" :data="pcaa" placeholder="请选择地级市" @change="areaSelectChange"></area-cascader>
          </div>
        </div>
        <div class="row">
          <label class="col-md-2 col-form-label">位置选择</label>
          <div class="col-md-6">
            <fg-input placeholder="可手动输入地级市(如：江苏省-南京市-江宁区)" v-model="eventForm.clock"></fg-input>
            <!-- <area-cascader v-model="selected" :level="1" type="text" :data="pcaa" placeholder="请选择"></area-cascader> -->
          </div>
          <!-- <div class="col-md-5">
            <code style="width:100%;line-height: 3;color:gray">可手动输入地级市(如：江苏省-南京市-江宁区)</code>
          </div> -->
        </div>
        <div class="row">
          <label class="col-md-2 col-form-label">异常事件</label>
          <div class="col-md-6">
            <fg-input placeholder="异常事件" v-model="eventForm.stop"></fg-input>
            <!-- <area-cascader v-model="selected" :level="1" type="text" :data="pcaa" placeholder="请选择"></area-cascader> -->
          </div>
        </div>
        <div class="row">
          <label class="col-md-2 col-form-label">事件图片</label>
          <div class="col-md-6">
          <el-upload ref="ossUpload" accept=".png, .jpg, .jpeg" :http-request="OssUpload" :multiple="true" :show-file-list="true" action="" list-type="picture-card"
          :on-remove="handleRemoveOss"
          :before-upload="beforeUploadOss"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          </div>
        </div>
      </form>
      <div slot="footer" class="dialog-footer">
        <p-button @click="dialogClockFormVisible = false">取 消</p-button>
        <p-button type="primary" @click="handleOrderClock">确 定</p-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Vue from "vue";
import dayjs from "dayjs";
import { extend } from "vee-validate";
import { required, numeric, regex, confirmed } from "vee-validate/dist/rules";
import {
  pca,
  pcaa
} from 'area-data'; // v5 or higher
import 'vue-area-linkage/dist/index.css'; // v2 or higher
import VueAreaLinkage from 'vue-area-linkage';
Vue.use(VueAreaLinkage)
extend("required", required);
extend("numeric", numeric);
extend("regex", regex);
extend("confirmed", confirmed);

import PPagination from "src/components/UIComponents/Pagination.vue";
import { TimeLine, TimeLineItem, Badge } from "src/components/UIComponents";
import {
  orderList,
  orderPlan,
  orderUpdate,
  orderDelete,
  orderTrack,
  orderClock,
  orderTrackQrcode
} from "src/api/oms";

import { driverComplete, carrierNameComplete, truckList } from "src/api/bas"
import { client } from 'src/util/OssClient'

export default {
  components: {
    PPagination,
    TimeLine,
    TimeLineItem,
    Badge
  },
  computed: {
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      this.pagination.total = this.tableRes.count;
      return this.tableRes.count;
    }
  },
  data() {
    return {
      selected: [],
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      tableRes: {},
      tableData: [],
      dataFilter: {
        fieldFilters: [],
        fieldSorts: null,
        fieldProjects: [],
        page: 1,
        length: 10
      },
      multipleSelection: [],
      dialogFormVisible: false,
      dispatchForm: {
        driver: "",
        plateNo: "",
        mobile: "",
        carrier: "",
        truckType: ""
      },
      searchForm: {
        no: ""
      },
      dialogEditFormVisible: false,
      dialogClockFormVisible: false,
      eventForm: {
        ids: [],
        // credentials: [],
        // stop: "",
        clock: ""
        // delivery: "",
        // cc: ""
      },
      editForm: {
        no: "",
        consignee: "",
        consigneeAddr: "",
        consigneeCode: "",
        consigneeContact: "",
        consigneeDock: "",

        consignor: "",
        consignorAddr: "",
        consignorCode: "",
        consignorContact: "",
        consignorDock: "",
        deliveryTime: "",
        shipTime: "",
        shipment: "",
        orderDate: ""
      },
      isAllocate: false,
      isSaveDriverInfo: false,
      orderStatus: {
        0: "取消",
        10: "新建",
        20: "计划",
        30: "派单",
        40: "揽件",
        50: "在途",
        60: "派送",
        70: "签收",
        80: "中转",
        90: "回单"
      },
      drawer: false,
      direction: "rtl",
      activities: [],
      activeName: "0",
      timelineName: "",
      fileList:[]
    };
  },
  created() {
    this.getList();
    this.pcaa = pcaa
    this.pca = pca
  },
  methods: {
    OssUpload(file) {
      let that = this
      console.log(file)
      var date = dayjs(new Date())
        .format("YYYYMMDD")
        .trim();
      var fileName = date+'_anji_' +file.file.uid+ "."+file.file.name.split('.')[1]
      that.fileList.push({uid:file.file.uid, ossPath:''})
      //定义唯一的文件名，打印出来的uid其实就是时间戳
      client().put("/tms/inbound/"+fileName, file.file).then(
        result => {
          that.fileList = that.fileList.map(item=>{
            if (file.file.uid == item.uid){
              item.ossPath = result.url.replace('http://icarhere.oss-cn-shanghai.aliyuncs.com','https://img.icarhere.com')
            }
            return item
          })
        })
    },
    handleRemoveOss(file, fileList) {
      let that = this
        console.log(file, fileList);
        that.fileList = that.fileList.filter(item=>{
          return item.uid!=file.uid
        })
      },
      beforeUploadOss(file){
        console.log(file)
         let types = ['image/jpeg', 'image/jpg', 'image/png'];
      const isImage = types.includes(file.type);
      if (!isImage) {
        this.$swal({
          title: "上传图片只能是 JPG、JPEG、PNG 格式!",
          timer: 3000,
          // text: 'Your imaginary file is safe :)',
          icon: "warning",
          confirmButtonText: "好的",
          confirmButtonClass: "btn btn-info btn-fill",
          buttonsStyling: false
        });
        return false;
      }
      // if (!isLtSize) {
      //   this.$message.error('上传图片大小不能超过 2MB!');
      //   return false;
      // }
      return true;
      },
      areaSelectChange(val) {
      console.log(val.join("-"))
      this.eventForm.cc = val.join("-")
      this.eventForm.clock = val.join("-")
    },
    timeLineIcon(index) {
      if (index === 0) {
        return "nc-icon nc-single-copy-04";
      }
      if (index === 1) {
        return "nc-icon nc-sun-fog-29";
      }
      if (index === 2) {
        return "nc-icon nc-world-2";
      }
      if (index === 3) {
        return "nc-icon nc-istanbul";
      }
    },
    timeLineType(index) {
      if (index === 0) {
        return "danger";
      }
      if (index === 1) {
        return "success";
      }
      if (index === 2) {
        return "info";
      }
      if (index === 3) {
        return "warning";
      }
    },
    resetForm() {
      this.dispatchForm = {
        driver: "",
        plateNo: "",
        carrier: "",
        mobile: "",
        truckType: ""
      };
      this.isAllocate = false;
      this.isSaveDriverInfo = false
    },
    resetEditForm() {
      this.editForm = {
        no: "",
        consignee: "",
        consigneeAddr: "",
        consigneeCode: "",
        consigneeContact: "",
        consigneeDock: "",

        consignor: "",
        consignorAddr: "",
        consignorCode: "",
        consignorContact: "",
        consignorDock: "",
        deliveryTime: "",
        shipTime: "",
        shipment: "",
        orderDate: ""
      };
      this.isAllocate = false;
      this.isSaveDriverInfo = false
    },
    formatterDate(row, column) {
      return dayjs(row)
        .format("YYYY-MM-DD")
        .trim();
    },
    formatterDate1(row, column) {
      return dayjs(row)
        .format("YYYY-MM-DD HH:mm")
        .trim();
    },
    pageChange(pagination) {
      this.page = pagination.currentPage;
      this.getList();
    },
    searchData() {
      this.pagination = {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      };
      this.getList();
    },
    getList() {
      let that = this;
      // orderList(this.getFilter())
      //   .then(function(res) {
      //     that.tableRes = res;
      //     that.tableData = res.data;
      //   })
      //   .catch(() => {});
      let planOrdersStr = localStorage.getItem("planOrders");
      that.tableData = JSON.parse(planOrdersStr);
    },
    getFilter() {
      this.dataFilter.length = this.pagination.perPage;
      this.dataFilter.page = this.pagination.currentPage;
      var filters = [];
      for (var i in this.searchForm) {
        if (this.searchForm[i]) {
          var json = { field: i, value: this.searchForm[i], predicate: "like" };
          filters.push(json);
        }
      }
      this.dataFilter.fieldFilters = filters;
      return this.dataFilter;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log(this.multipleSelection);
    },
    handleSelect0(item) {
      console.log(item);
      this.dispatchForm.driver = item.value.split("/")[0]
      this.dispatchForm.plateNo = item.value.split("/")[1]
      this.dispatchForm.mobile = item.value.split("/")[2]

    },
    handleSelect1(item) {
      console.log(item);
      this.dispatchForm.driver = item.value.split("/")[1]
      this.dispatchForm.plateNo = item.value.split("/")[0]
      this.dispatchForm.mobile = item.value.split("/")[2]

    },
    handleSelect2(item) {
      console.log(item);
      this.dispatchForm.driver = item.value.split("/")[1]
      this.dispatchForm.plateNo = item.value.split("/")[2]
      this.dispatchForm.mobile = item.value.split("/")[0]

    },
    querySearchAsync0(queryString, cb) {
      var r = []
      if (queryString.length < 1 ) {
        cb(r)
        return
      }
      driverComplete(queryString).then(res => {
        for (var i in res.data) {
          var j = {}
          j['value'] = res.data[i].name + "/"+ res.data[i].plateNo + "/"+res.data[i].mobile 
          r.push(j)
        }
        cb(r)
      })
      
    },
    querySearchAsync1(queryString, cb) {
      var r = []
      if (queryString.length < 2 ) {
        cb(r)
        return
      }
      driverComplete(queryString).then(res => {
        for (var i in res.data) {
          var j = {}
          j['value'] = res.data[i].plateNo+ "/"+res.data[i].mobile + "/" + res.data[i].name 
          r.push(j)
        }
        cb(r)
      })
    },
    querySearchAsync2(queryString, cb) {
      var r = []
      if (queryString.length < 3 ) {
        cb(r)
        return
      }
      driverComplete(queryString).then(res => {
        for (var i in res.data) {
          var j = {}
          j['value'] = res.data[i].mobile + "/" +res.data[i].name + "/"+ res.data[i].plateNo
          r.push(j)
        }
        cb(r)
      })
    },
    querySearchCarrierAsync(queryString, cb) {
      var r = []
      if (queryString.length === 0 ) {
        cb(r)
        return
      }
      carrierNameComplete(queryString).then(res => {
        for (var i in res.data) {
          var j = {}
          j['value'] = res.data[i].name + "/" +res.data[i].key
          r.push(j)
        }
        cb(r)
      })
    },
    handleSelectCarrier(item) {
      console.log(item);
      this.dispatchForm.carrier = item.value.split("/")[0]

    },
    querySearchTruckAsync(queryString, cb) {
      var r = []
      if (queryString.length === 0 ) {
        cb(r)
        return
      }
      let model = {
        fieldFilters: [{field: "vehicleType", value: queryString, predicate: "like"}],
        fieldProjects: [],
        fieldSorts: [],
        length: 9999,
        page:1
      }
      truckList(model).then(res => {
        for (var i in res.data) {
          var j = {}
          j['value'] = res.data[i].vehicleType
          r.push(j)
        }
        cb(r)
      })
    },
    handleSelectTruck(item) {
      console.log(item);
      this.dispatchForm.truckType = item.value.split("/")[0]

    },
    dispatch() {
      let that = this;
      if (that.multipleSelection.length <= 0) {
        that.$swal({
          title: "请选择订单",
          timer: 3000,
          // text: 'Your imaginary file is safe :)',
          icon: "warning",
          confirmButtonText: "好的",
          confirmButtonClass: "btn btn-info btn-fill",
          buttonsStyling: false
        });
        return;
      }
      this.resetForm();
      this.dialogFormVisible = true;
    },
    handleOrderPlan() {
      let that = this;
      that.dispatchForm["orders"] = that.multipleSelection;
      that
        .$swal({
          title: that.isAllocate ? "确定发运并派单吗?" : "确定发运吗？",
          text: that.isAllocate
            ? "此操作订单发运并同时派单"
            : "此操作订单仅发运",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          confirmButtonClass: "btn btn-success btn-fill",
          cancelButtonClass: "btn btn-danger btn-fill",
          buttonsStyling: false
        })
        .then(function(res) {
          if (res.dismiss === "cancel") {
            that.$swal({
              title: "已取消",
              // text: 'Your imaginary file is safe :)',
              icon: "error",
              timer: 3000,
              confirmButtonClass: "btn btn-info btn-fill",
              buttonsStyling: false
            });
          } else {
            orderPlan(that.isAllocate ? 1 : 0, that.dispatchForm).then(res => {
              that.$swal({
                title: that.isAllocate ? "已发运并派单" : "已发运",
                timer: 3000,
                // text: 'Your imaginary file has been deleted.',
                icon: "success",
                confirmButtonClass: "btn btn-success btn-fill",
                buttonsStyling: false
              });
              that.dialogFormVisible = false;
              that.getList();
            });
          }
        });
    },
    handleOrderEdit() {
      let that = this;
      that
        .$swal({
          title: "确定修改该订单吗？",
          // text: that.isAllocate?"此操作订单发运并同时派单":"此操作订单仅发运",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          confirmButtonClass: "btn btn-success btn-fill",
          cancelButtonClass: "btn btn-danger btn-fill",
          buttonsStyling: false
        })
        .then(function(res) {
          if (res.dismiss === "cancel") {
            that.$swal({
              title: "已取消",
              // text: 'Your imaginary file is safe :)',
              icon: "error",
              timer: 3000,
              confirmButtonClass: "btn btn-info btn-fill",
              buttonsStyling: false
            });
          } else {
            orderUpdate(that.editForm).then(res => {
              that.$swal({
                title: "已修改",
                timer: 3000,
                // text: 'Your imaginary file has been deleted.',
                icon: "success",
                confirmButtonClass: "btn btn-success btn-fill",
                buttonsStyling: false
              });
              that.dialogEditFormVisible = false;
              that.getList();
            });
          }
        });
    },
    deleteOrder(deleteFlg) {
      let that = this;
      if (that.multipleSelection.length != 1) {
        that.$swal({
          title: "请选择一个订单",
          timer: 3000,
          // text: 'Your imaginary file is safe :)',
          icon: "warning",
          confirmButtonText: "好的",
          confirmButtonClass: "btn btn-info btn-fill",
          buttonsStyling: false
        });
        return;
      }
      if (deleteFlg === 1) {
        that
          .$swal({
            title: "确定删除选中的订单吗?",
            text: "此操作将会彻底删除订单!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            confirmButtonClass: "btn btn-success btn-fill",
            cancelButtonClass: "btn btn-danger btn-fill",
            buttonsStyling: false
          })
          .then(function(res) {
            if (res.dismiss === "cancel") {
              that.$swal({
                title: "已取消",
                // text: 'Your imaginary file is safe :)',
                icon: "error",
                timer: 3000,
                confirmButtonClass: "btn btn-info btn-fill",
                buttonsStyling: false
              });
            } else {
              orderDelete(deleteFlg, { id: that.multipleSelection[0].id }).then(
                res => {
                  that.$swal({
                    title: "已删除",
                    timer: 3000,
                    // text: 'Your imaginary file has been deleted.',
                    icon: "success",
                    confirmButtonClass: "btn btn-success btn-fill",
                    buttonsStyling: false
                  });
                  that.getList();
                }
              );
            }
          });
      } else {
        that
          .$swal({
            title: "确定撤回选中的订单吗?",
            text: "此操作将会撤回订单到初始状态!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            confirmButtonClass: "btn btn-success btn-fill",
            cancelButtonClass: "btn btn-danger btn-fill",
            buttonsStyling: false
          })
          .then(function(res) {
            if (res.dismiss === "cancel") {
              that.$swal({
                title: "已取消",
                timer: 3000,
                // text: 'Your imaginary file is safe :)',
                icon: "error",
                confirmButtonClass: "btn btn-info btn-fill",
                buttonsStyling: false
              });
            } else {
              orderDelete(deleteFlg, { id: that.multipleSelection[0].id }).then(
                res => {
                  that.$swal({
                    title: "已撤回",
                    timer: 3000,
                    // text: 'Your imaginary file has been deleted.',
                    icon: "success",
                    confirmButtonClass: "btn btn-success btn-fill",
                    buttonsStyling: false
                  });
                  that.getList();
                }
              );
            }
          });
      }
    },
    orderUpdate() {
      let that = this;
      if (that.multipleSelection.length != 1) {
        that.$swal({
          title: "请选择一个订单",
          timer: 3000,
          // text: 'Your imaginary file is safe :)',
          icon: "warning",
          confirmButtonText: "好的",
          confirmButtonClass: "btn btn-info btn-fill",
          buttonsStyling: false
        });
        return;
      }
      if (that.multipleSelection[0].status > 40) {
        that.$swal({
          title: "该状态不能修改",
          timer: 3000,
          // text: 'Your imaginary file is safe :)',
          icon: "warning",
          confirmButtonText: "好的",
          confirmButtonClass: "btn btn-info btn-fill",
          buttonsStyling: false
        });
        return;
      }
      this.resetEditForm();
      this.editForm = JSON.parse(JSON.stringify(that.multipleSelection[0]));
      this.dialogEditFormVisible = true;
    },
    clock() {
      let that = this;
      if (that.multipleSelection.length === 0) {
        that.$swal({
          title: "请至少选择一个车次",
          timer: 3000,
          // text: 'Your imaginary file is safe :)',
          icon: "warning",
          confirmButtonText: "好的",
          confirmButtonClass: "btn btn-info btn-fill",
          buttonsStyling: false
        });
        return;
      }
      that.resetEventForm();
      that.dialogClockFormVisible = true;
    },
    resetEventForm() {
      this.eventForm = {
        ids: [],
        // credentials: [],
        // stop: "",
        clock: ""
        // delivery: "",
        // cc: ""
      };
      if (this.$refs.ossUpload){
            this.$refs.ossUpload.clearFiles()
          }
          this.fileList = []
          this.selected = []
    },
    handleOrderClock() {
      let that = this;
      if (that.eventForm.clock==''){
        that.$swal({
          title: "打卡位置必填",
          timer: 3000,
          // text: 'Your imaginary file is safe :)',
          icon: "warning",
          confirmButtonText: "好的",
          confirmButtonClass: "btn btn-info btn-fill",
          buttonsStyling: false
        });
        return;
      }
       that.eventForm.cc=''
      if (that.fileList.length>0){
        that.fileList = that.fileList.filter(item=>{
          return item.ossPath!=''
        })
        if (that.fileList.length>0){
          let pisc = []
          that.fileList.forEach(f=>{
            pisc.push(f.ossPath)
          })
          that.eventForm.credentials = pisc
        }
      }
      console.log(that.eventForm)
      for (var i in that.multipleSelection) {
        that.eventForm.ids.push(that.multipleSelection[i].id);
      }
      orderClock(that.eventForm).then(res => {
        that.$swal({
          title: "已打卡",
          timer: 3000,
          // text: 'Your imaginary file has been deleted.',
          icon: "success",
          confirmButtonClass: "btn btn-success btn-fill",
          buttonsStyling: false
        });
        that.dialogClockFormVisible = false;
        that.getList();
      });
    },
    onCheckboxChange(val) {
      console.log(val);
      this.isAllocate = val;
    },
    onCheckboxChange1(val) {
      console.log(val);
      this.isSaveDriverInfo = val;
    },
    getTrackInfo(row) {
      var that = this;
      localStorage.setItem("order", JSON.stringify(row))
      this.$router.push({path: '/oms/truck'})

    },
    getOrderDetail (row) {
      var that = this;
      localStorage.setItem("order", JSON.stringify(row))
      this.$router.push({path: '/oms/detail'})
    },
    getTrackQrcode (id) {
      let that = this
      orderTrackQrcode(id).then(res => {
        if (res.action === 'ok') {
          that.$swal({ 
            title: res.no, 
            confirmButtonText: '关闭',
            text: '请用微信扫一扫',
            imageUrl: res.data,
            imageWidth: 200,
            imageHeight: 200,
            animation: false
          })
        }
      })
    }
  }
};
</script>
<style lang="scss" scoped>
.el-table .td-actions {
  button.btn {
    margin-right: 5px;
  }
}
/deep/ .el-dialog__wrapper {
  z-index: 1503 !important;
}
/deep/ .v-modal {
  z-index: 1502 !important;
}

/deep/ .el-drawer {
  overflow: scroll !important;
  display: block !important;
}

/deep/ .el-drawer.rtl {
  overflow: scroll !important;
}
/deep/ .el-input__inner:focus{outline:none;

    border: 0.5px solid #9A9A9A;}

   /deep/  input::-webkit-input-placeholder {
    color: #9A9A9A;
  }
 /deep/  input::-moz-input-placeholder {
    color: #9A9A9A;
  }
 /deep/  input::-ms-input-placeholder {
    color: #9A9A9A;
  }
  /deep/ .area-select {
  position: relative;
  display: inline-block;
  vertical-align: top;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 40px !important;
  cursor: pointer;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #e1e2e6;
}

/deep/ .area-select.large {
  width: 100% !important;
  margin-bottom: 10px !important;
}
</style>
